<template>
    <div class="view-container ">
        <div class="card question-table">
            <div class="card justify-content-center passings">
                <div class="home-block__title title">Тестирования</div>
                <div class="home-block__title title">Вам доступно {{ userTrajectoryCount }} траекторий, пройдите
                    тестирование, чтобы получить назначение на тренажеры
                </div>
                <passing-table/>
            </div>
        </div>
    </div>
</template>

<script>
import PassingTable from "../../feature/testing/tables/PassingTable.vue";
import {computed, onBeforeMount, ref, watch} from "@vue/runtime-core";
import {useStore} from "vuex";

export default {
    components: {PassingTable},
    name: "passing-page",
    setup() {
        const store = useStore(),
         profile = computed(() => store.state.profile.profile),
         userTrajectoryCount = ref(0)
        onBeforeMount(() => {
            loadSummary();
        });
        const loadSummary = () => {
            if (profile.value && profile.value.id) {
                store.dispatch("users/getUserTrajectories", profile.value?.id)
                    .then(() => userTrajectoryCount.value = computed(() => store.state.users.userTrajectories))
            }
        };

        watch(profile, () => {
            loadSummary();
        });

        return {
            userTrajectoryCount
        }
    }
};
</script>

<style lang="scss" scoped>
.passings {
    flex-direction: column !important;
    padding: 2% 25px !important;

    .title {
        padding-top: 15px;
    }

    & > div {
        flex: 1;
    }
}
</style>